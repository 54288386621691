/**
 * Typography
 *
 * Font, line-height, and color for body text, headings, and more.
 */
/**
	See MDN (https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping)
	for motivation for font weight values.

	Avenir-Light	= 100
	Avenir-Book		= 300
	Avenir-Roman	= 400
	Avenir-Medium	= 500
	Avenir-Heavy	= 700
	Avenir-Black	= 900
*/
@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Light"), url("/assets/fonts/Avenir-Light.ttf") format("truetype");
  font-weight: 100; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-LightOblique"), url("/assets/fonts/Avenir-LightOblique.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Book"), url("/assets/fonts/Avenir-Book.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-BookOblique"), url("/assets/fonts/Avenir-BookOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Roman"), url("/assets/fonts/Avenir-Roman.ttf") format("truetype");
  font-weight: 400; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Oblique"), url("/assets/fonts/Avenir-Oblique.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Medium"), url("/assets/fonts/Avenir-Medium.ttf") format("truetype");
  font-weight: 500; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-MediumOblique"), url("/assets/fonts/Avenir-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Heavy"), url("/assets/fonts/Avenir-Heavy.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-HeavyOblique"), url("/assets/fonts/Avenir-HeavyOblique.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Black"), url("/assets/fonts/Avenir-Black.ttf") format("truetype");
  font-weight: 900; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-BlackOblique"), url("/assets/fonts/Avenir-BlackOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

.nv.ReactTable {
  color: #97999B;
  border: none;
  background: #fff; }
  .nv.ReactTable .rt-th,
  .nv.ReactTable .rt-td {
    min-height: 3.5em;
    padding: .75em 0;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center; }
  .nv.ReactTable .rt-th {
    font-weight: 900;
    text-transform: uppercase;
    text-align: left; }
  .nv.ReactTable .rt-th.-sort-asc,
  .nv.ReactTable .rt-th.-sort-desc {
    font-weight: 900;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #665A7F;
    box-shadow: none;
    transition: color .2s ease-in-out;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent; }
    div:first-child .nv.ReactTable .rt-th.-sort-asc::after, div:first-child
    .nv.ReactTable .rt-th.-sort-desc::after {
      content: "";
      display: block;
      margin-left: .5ex;
      height: 1.25em;
      width: 1em;
      transition: background-color .2s ease-in-out; }
  .nv.ReactTable .rt-th.-sort-desc {
    border-bottom: 3px solid #665A7F; }
    div:first-child .nv.ReactTable .rt-th.-sort-desc::after {
      content: "";
      background-color: inherit; }
  .nv.ReactTable .rt-th.-sort-asc {
    border-top: 3px solid #665A7F; }
    div:first-child .nv.ReactTable .rt-th.-sort-asc::after {
      content: "";
      background-color: inherit; }
  .nv.ReactTable .rt-td {
    font-weight: 500;
    color: inherit;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center; }
    .nv.ReactTable .rt-td :last-child {
      justify-content: end; }
  .nv.ReactTable .rt-tbody .rt-tr {
    padding: 0; }
    .nv.ReactTable .rt-tbody .rt-tr:hover, .nv.ReactTable .rt-tbody .rt-tr:focus {
      box-shadow: 0px 1px 3px rgba(45, 0, 217, 0.05), 0px -1px 3px rgba(45, 0, 217, 0.05); }
  .nv.ReactTable .rt-tr-expansion {
    padding: 1em;
    color: #a79eba;
    background: #25313E;
    border-bottom: 1px solid #ebebeb; }
    .nv.ReactTable .rt-tr-expansion h6 {
      font-weight: 900; }
  .nv.ReactTable .rt-thead .rt-th.rt-expandable,
  .nv.ReactTable .rt-thead .rt-td.rt-expandable,
  .nv.ReactTable .rt-tbody .rt-td.rt-expandable {
    flex: 10 0 auto !important;
    width: 10px !important; }
  .nv.ReactTable .rt-thead {
    background-color: #fafafa;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb; }
  .nv.ReactTable .rt-thead.-header {
    box-shadow: none; }
    .nv.ReactTable .rt-thead.-header .rt-tr .rt-th {
      font-weight: 900;
      padding: 1em;
      text-transform: uppercase; }
  .nv.ReactTable .rt-thead .rt-th,
  .nv.ReactTable .rt-thead .rt-td,
  .nv.ReactTable .rt-tbody .rt-td {
    padding: 0 1em;
    border-right: none; }
  .nv.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none; }
  .nv.ReactTable .rt-tbody .rt-tr {
    border-bottom: 1px solid #ebebeb; }
    .nv.ReactTable .rt-tbody .rt-tr.-even {
      background: rgba(45, 0, 217, 0.03); }
  .nv.ReactTable .-pagination {
    padding: 1em 0;
    border: none;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center; }
    .nv.ReactTable .-pagination > div,
    .nv.ReactTable .-pagination > menu {
      display: flex;
      flex-flow: row nowrap;
      align-items: center; }
    .nv.ReactTable .-pagination menu {
      margin: 0;
      padding: 0;
      list-style: none; }
    .nv.ReactTable .-pagination li {
      display: inherit; }
    .nv.ReactTable .-pagination p {
      color: currentColor;
      opacity: 0.5;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px; }
  .nv.ReactTable .-pagination button {
    width: 2em;
    height: 2em;
    padding: 0;
    border-radius: 2px;
    overflow: hidden;
    appearance: none;
    background: none;
    border: none;
    color: currentColor;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    display: flex;
    justify-content: center; }
    .nv.ReactTable .-pagination button svg {
      width: auto;
      height: 12px;
      display: block; }
      .nv.ReactTable .-pagination button svg path {
        fill: currentColor; }
    .nv.ReactTable .-pagination button span {
      position: absolute;
      width: 1px;
      height: 1px;
      visibility: hidden; }
    .nv.ReactTable .-pagination button:not(.current) {
      opacity: 0.5; }
    .nv.ReactTable .-pagination button.current {
      font-weight: 900;
      background: #665A7F;
      color: #fff; }
    .nv.ReactTable .-pagination button[disabled] {
      opacity: 0.25;
      cursor: initial; }
