/**
 * Typography
 *
 * Font, line-height, and color for body text, headings, and more.
 */
/**
	See MDN (https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping)
	for motivation for font weight values.

	Avenir-Light	= 100
	Avenir-Book		= 300
	Avenir-Roman	= 400
	Avenir-Medium	= 500
	Avenir-Heavy	= 700
	Avenir-Black	= 900
*/
@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Light"), url("/assets/fonts/Avenir-Light.ttf") format("truetype");
  font-weight: 100; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-LightOblique"), url("/assets/fonts/Avenir-LightOblique.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Book"), url("/assets/fonts/Avenir-Book.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-BookOblique"), url("/assets/fonts/Avenir-BookOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Roman"), url("/assets/fonts/Avenir-Roman.ttf") format("truetype");
  font-weight: 400; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Oblique"), url("/assets/fonts/Avenir-Oblique.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Medium"), url("/assets/fonts/Avenir-Medium.ttf") format("truetype");
  font-weight: 500; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-MediumOblique"), url("/assets/fonts/Avenir-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Heavy"), url("/assets/fonts/Avenir-Heavy.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-HeavyOblique"), url("/assets/fonts/Avenir-HeavyOblique.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Black"), url("/assets/fonts/Avenir-Black.ttf") format("truetype");
  font-weight: 900; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-BlackOblique"), url("/assets/fonts/Avenir-BlackOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

.custom-tooltip.custom-tooltip--average-call-quality {
  width: 150px;
  display: flex;
  flex-direction: column; }
  .custom-tooltip.custom-tooltip--average-call-quality .tooltip__timestamp h5 {
    font-weight: 500;
    margin-top: .25em;
    text-align: center;
    color: #665A7F; }
  .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics {
    display: flex;
    flex-direction: row; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics .metric {
      padding: .5rem 0;
      display: flex;
      flex: 1 33%;
      flex-direction: column;
      text-align: center; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics .metric__label {
      order: 2;
      margin-top: .25em;
      text-transform: uppercase;
      font-size: 8px; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics .metric__value {
      font-weight: 500;
      order: 1;
      font-size: 16px; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics .metric--average .metric__value {
      color: #4EA96B; }
  .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown {
    display: flex;
    flex-direction: column; }
  .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__name,
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__metric {
      width: 33%;
      font-size: 10px; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__name {
      font-weight: 900;
      text-transform: uppercase; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__name--nvnet {
      color: #97999B; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__name--access {
      color: #4EA7DA; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__name--local {
      color: #665A7F; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__metric {
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0; }
      .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__metric dd, .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__metric dt {
        margin: 0;
        padding: 0; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__metric .metric__label {
      order: 2;
      color: #4EA96B; }
    .custom-tooltip.custom-tooltip--average-call-quality .tooltip__metrics-breakdown .segment .segment__metric .metric__value {
      order: 1;
      margin-right: 1ch; }
