.form.form--current-report-filters {
 
  .form-group {
    margin-left: 1rem;
  }
  
  .form-group select.form-control{
    height: 3.25em;
  }

}