/**
	See MDN (https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping)
	for motivation for font weight values.

	Avenir-Light	= 100
	Avenir-Book		= 300
	Avenir-Roman	= 400
	Avenir-Medium	= 500
	Avenir-Heavy	= 700
	Avenir-Black	= 900
*/

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-Light'),
		url('/src/theme/fonts/Avenir-Light.ttf') format('truetype');
	font-weight: 100;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-LightOblique'),
		url('/src/theme/fonts/Avenir-LightOblique.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-Book'),
		url('/src/theme/fonts/Avenir-Book.ttf') format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-BookOblique'),
		url('/src/theme/fonts/Avenir-BookOblique.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-Roman'),
		url('/src/theme/fonts/Avenir-Roman.ttf') format('truetype');
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-Oblique'),
		url('/src/theme/fonts/Avenir-Oblique.ttf') format('truetype');
	font-style: italic;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-Medium'),
		url('/src/theme/fonts/Avenir-Medium.ttf') format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-MediumOblique'),
		url('/src/theme/fonts/Avenir-MediumOblique.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-Heavy'),
		url('/src/theme/fonts/Avenir-Heavy.ttf') format('truetype');
	font-weight: 700;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-HeavyOblique'),
		url('/src/theme/fonts/Avenir-HeavyOblique.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-Black'),
		url('/src/theme/fonts/Avenir-Black.ttf') format('truetype');
	font-weight: 900;
}

@font-face {
	font-family: 'Avenir';
	src:
		local('Avenir-BlackOblique'),
		url('/src/theme/fonts/Avenir-BlackOblique.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@mixin AvenirBook {
	font-weight: 300;
}

@mixin AvenirBookOblique {
	@include AvenirBook();
	font-style: italic;
}

@mixin AvenirMedium {
	font-weight: 500;
}

@mixin AvenirMediumOblique {
	@include AvenirMedium();
	font-style: italic;
}

@mixin AvenirBlack {
	font-weight: 900;
}

@mixin AvenirBlackOblique {
	@include AvenirBlack();
	font-style: italic;
}
