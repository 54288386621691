/**
 * Typography
 *
 * Font, line-height, and color for body text, headings, and more.
 */
/**
	See MDN (https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping)
	for motivation for font weight values.

	Avenir-Light	= 100
	Avenir-Book		= 300
	Avenir-Roman	= 400
	Avenir-Medium	= 500
	Avenir-Heavy	= 700
	Avenir-Black	= 900
*/
@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Light"), url("/assets/fonts/Avenir-Light.ttf") format("truetype");
  font-weight: 100; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-LightOblique"), url("/assets/fonts/Avenir-LightOblique.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Book"), url("/assets/fonts/Avenir-Book.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-BookOblique"), url("/assets/fonts/Avenir-BookOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Roman"), url("/assets/fonts/Avenir-Roman.ttf") format("truetype");
  font-weight: 400; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Oblique"), url("/assets/fonts/Avenir-Oblique.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Medium"), url("/assets/fonts/Avenir-Medium.ttf") format("truetype");
  font-weight: 500; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-MediumOblique"), url("/assets/fonts/Avenir-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Heavy"), url("/assets/fonts/Avenir-Heavy.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-HeavyOblique"), url("/assets/fonts/Avenir-HeavyOblique.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Black"), url("/assets/fonts/Avenir-Black.ttf") format("truetype");
  font-weight: 900; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-BlackOblique"), url("/assets/fonts/Avenir-BlackOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

.graph.graph--call-qos-statistics {
  height: 18.75rem;
  opacity: 1;
  transition: all .5s ease-in-out; }
  .graph.graph--call-qos-statistics.isLoading {
    height: 0;
    opacity: .25; }
