/**
 * Typography
 *
 * Font, line-height, and color for body text, headings, and more.
 */
@import 'fonts/Avenir';

@mixin typography {
	font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// stylelint-disable value-keyword-case
$font-family-sans-serif:	'Avenir', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif !default;
$font-family-monospace:		source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base:				$font-family-sans-serif !default;


// stylelint-enable value-keyword-case
$font-size-base:              0.75rem !default; // 12px default font size
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;
$font-weight-black:           900 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.333333 !default; //16px

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;
