/**
 * Typography
 *
 * Font, line-height, and color for body text, headings, and more.
 */
/**
	See MDN (https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping)
	for motivation for font weight values.

	Avenir-Light	= 100
	Avenir-Book		= 300
	Avenir-Roman	= 400
	Avenir-Medium	= 500
	Avenir-Heavy	= 700
	Avenir-Black	= 900
*/
@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Light"), url("/assets/fonts/Avenir-Light.ttf") format("truetype");
  font-weight: 100; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-LightOblique"), url("/assets/fonts/Avenir-LightOblique.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Book"), url("/assets/fonts/Avenir-Book.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-BookOblique"), url("/assets/fonts/Avenir-BookOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Roman"), url("/assets/fonts/Avenir-Roman.ttf") format("truetype");
  font-weight: 400; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Oblique"), url("/assets/fonts/Avenir-Oblique.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Medium"), url("/assets/fonts/Avenir-Medium.ttf") format("truetype");
  font-weight: 500; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-MediumOblique"), url("/assets/fonts/Avenir-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Heavy"), url("/assets/fonts/Avenir-Heavy.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-HeavyOblique"), url("/assets/fonts/Avenir-HeavyOblique.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-Black"), url("/assets/fonts/Avenir-Black.ttf") format("truetype");
  font-weight: 900; }

@font-face {
  font-family: 'Avenir';
  src: local("Avenir-BlackOblique"), url("/assets/fonts/Avenir-BlackOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

.ReactTable.data-table--account-activity .rt-tr-expansion {
  display: flex;
  justify-content: space-between; }
  .ReactTable.data-table--account-activity .rt-tr-expansion .details {
    padding: 1rem;
    width: 25%;
    margin-right: .5rem;
    background-color: rgba(102, 90, 127, 0.25);
    background-color: rgba(102, 90, 127, 0.25);
    border-radius: 10px; }
  .ReactTable.data-table--account-activity .rt-tr-expansion h5 {
    margin-bottom: 1rem;
    padding-bottom: .5em;
    line-height: 1em;
    border-bottom: 1px solid #a79eba; }
  .ReactTable.data-table--account-activity .rt-tr-expansion dl dt,
  .ReactTable.data-table--account-activity .rt-tr-expansion dl dd {
    float: left;
    margin-bottom: 1rem; }
  .ReactTable.data-table--account-activity .rt-tr-expansion dl dt {
    clear: both;
    margin-right: 1em;
    color: #a79eba;
    text-transform: capitalize; }
  .ReactTable.data-table--account-activity .rt-tr-expansion dl dd {
    color: white; }
