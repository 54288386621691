/**
* nexVortex Unified Portal -  Design System Theme Extension - Colors
*
* @todo mschaal - Deprecate in favor of @nexvortex/ui-system package imports.
* @see https://getbootstrap.com/docs/4.1/getting-started/theming/#functions
*/

/**
* Color value variables.
*
* Defines a set of custom theme color variables
* from the nexVortex UI system to be used by other
* color functions and maps in all theming ecosystems.
*/
$color-jade: #429577;
$color-crimson: #CA5057;
$color-tiger: #FF8C69;
$color-gold: #FFBE65;
$color-emerald: #4EA96B;
$color-midnight: #25313E;
$color-punch: #FF696C;
$color-cerulean: #4EA7DA;
$color-grape: #665A7F;
$color-pewter: #97999B;
$color-white: #ffffff;
$color-eggshell: #f5f5f5;

/**
* Color value map definitions.
*
* Defines various map objects which
* resolve keys back to defined sets of
* color values in the theme ecosystems.
* 
* @todo Consider defining all colors in service-them-colors()
* and then change the color() function to reference these values.
* This might deviate from standard Bootstrap theming
* usage, so for now we track theme-colors and colors as separate maps.
*
* See https://getbootstrap.com/docs/4.1/getting-started/theming/#theme-colors
*/

// Defines a map of custom maps for $theme-color values by portal domain.
$service-theme-colors: (
	"default": (
		"primary": $color-cerulean,
		"secondary": $color-jade,
		"success": $color-emerald,
		"danger": $color-crimson,
		"warning": $color-gold,
		"info": $color-cerulean,
		"light": $color-eggshell,
		"dark": $color-midnight,
		"muted": $color-pewter,
		"white": $color-white,
	),
	"auth": (
		"primary": $color-crimson,
		"secondary": $color-jade,
		"success": $color-emerald,
		"danger": $color-crimson,
		"warning": $color-gold,
		"info": $color-cerulean,
		"light": $color-eggshell,
		"dark": $color-midnight,
		"muted": $color-pewter,
		"white": $color-white,
	),
	"nvsm": (
		"primary": $color-grape,
		"secondary": $color-jade,
		"success": $color-emerald,
		"danger": $color-crimson,
		"warning": $color-gold,
		"info": $color-cerulean,
		"light": $color-eggshell,
		"dark": $color-midnight,
		"muted": $color-pewter,
		"white": $color-white,
	),
);

// Defines a custom map of $colors to override Bootstraps theme.
// See https://github.com/twbs/bootstrap/blob/v4.3.1/scss/_variables.scss for defaults.
$colors: (
	white: $color-white,
	lightGray: $color-eggshell,
	gray: $color-pewter,
	red: $color-crimson,
	blue: $color-cerulean,
	indigo: $color-cerulean,
	midnight: $color-midnight,
	cerulean: $color-cerulean,
	green: $color-emerald,
	black: $color-midnight,
) !default;